<template>
    <a-drawer
        v-model:visible="visible"
        :maskClosable="false"
        title="物料信息录入详情"
        :closable="false"
        @close="handleCancel"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-descriptions title="" :column="2">
            <a-descriptions-item label="作业单号">
                {{ detailData?.importNo }}
            </a-descriptions-item>
            <a-descriptions-item label="行号">
                {{ detailData?.lineNo }}
            </a-descriptions-item>
            <a-descriptions-item label="采购订单号">
                {{ detailData?.purchaseOrderCode }}
            </a-descriptions-item>
            <a-descriptions-item label="供应商">
                {{ detailData?.supplier }}
            </a-descriptions-item>
            <a-descriptions-item label="供应商编码">
                {{ detailData?.supplierCode }}
            </a-descriptions-item>
            <a-descriptions-item label="物料名称">
                {{ detailData?.itemName }}
            </a-descriptions-item>
            <a-descriptions-item label="物料编码">
                {{ detailData?.itemCode }}
            </a-descriptions-item>
            <a-descriptions-item label="规格型号">
                {{ detailData?.itemSpec }}
            </a-descriptions-item>
            <a-descriptions-item label="物料条码">
                {{ detailData?.barCode }}
            </a-descriptions-item>
        </a-descriptions>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs, computed } from "vue";
import { apiSupplierlMaterialDetail } from "@/api";

export default defineComponent({
    props: ["detailId"],
    emits: ["update:detailId"],
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            detailData: null,
        });

        const handleCancel = () => {
            emit("update:detailId");
        };

        const getDetailData = async id => {
            let res = await apiSupplierlMaterialDetail(id);
            if (res.status === "SUCCESS") {
                state.detailData = res.data;
            }
        };

        watch(
            () => props.detailId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getDetailData(newVal);
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
